// Popper
import '@popperjs/core';

// Bootstrap
import * as bootstrap from 'bootstrap';

// Attach bootstrap to the window object
window.bootstrap = bootstrap;

// signature_pad
import SignaturePad from 'signature_pad';
window.SignaturePad = SignaturePad;
